.filter-controls {
  display: flex;
  justify-content: space-evenly;
  gap: .5rem;
  flex-wrap: wrap;
  align-items: center;
}
.filter-controls .line1 {
  flex: 1;
  min-width: 17rem;
  gap: .5em;
  margin-bottom: 2rem;
}
.filter-controls .line2 {
  flex: 1;
  gap: .5em;
  min-width: 17rem;
  display: flex;
  margin-bottom: 2rem;
}

