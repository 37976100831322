.filter-controls {
  display: flex;
  justify-content: space-evenly;
  gap: .5rem;
  flex-wrap: wrap;
  align-items: center;
}
.filter-controls .line1 {
  flex: 1;
  min-width: 17rem;
  gap: .5em;
  margin-bottom: 2rem;
}
.filter-controls .line2 {
  flex: 1;
  gap: .5em;
  min-width: 17rem;
  display: flex;
  margin-bottom: 2rem;
}

.quote-name {
  font-weight: 600;
  margin-bottom: .2rem;
}
.approval-type {
  text-transform: uppercase;
}

.quote-status {
  /*
  overflow: hidden;
  text-transform: uppercase;
  text-overflow: ellipsis;
  white-space: nowrap;
  */
  text-transform: uppercase;
  word-break:break-all;
  min-width: 14rem;
}

.quote-id {
  font-weight: 400;
  font-size: .9rem;
  text-decoration: underline; 
  color: blue;
}


