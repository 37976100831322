.quote-quick-view-header {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.header {
  font-size: 1rem;
  font-weight: 600;
}
.label {
  font-size: 1rem;
  font-weight: 600;
  margin-right: .4rem;
}
.configurations-tab-contents :global(.ant-collapse-item) :global(.ant-collapse-header) {
  font-weight: 500;
  font-size: 1rem;
  padding-left: 0;
  
}
.configurations-tab-contents :global(.ant-collapse-content) {
  .padding-left: 0;
  .padding-right: 0;
  .padding-top: 0;
}
.configurations-tab-contents :global(.ant-collapse-item) .quick-asm {
  padding: .5em;
}
.configurations-tab-contents :global(.ant-collapse-item) .quick-asm:nth-child(odd) {
  background-color: rgba( 0,0,0,0.04);
}
.configurations-tab-contents :global(.ant-collapse-item) .quick-asm > div {
  font-size:.8rem;
}
.configurations-tab-contents :global(.ant-collapse-item) .quick-asm > div:first-child {
}
.details-tab-contents { 
  display: flex;
  flex-wrap: wrap;
}

.details-tab-contents > div { 
  padding: 0em;
  padding-right: 3em;
  padding-bottom: 1em;
}

.details-tab-contents > div > div:first-child { 
  font-weight: 600;
}
.quoteQuickView-description th:global(.ant-descriptions-item) {
padding-bottom: 0px;
}
.quoteQuickView-description :global(.ant-descriptions-item-label) {
color: black;
font-weight: 600;
}
.quoteQuickView-description :global(.ant-descriptions-item-content) {
color: black;
font-weight: 400;
}

.performance-description th:global(.ant-descriptions-item) {
padding-bottom: 0px;
}
.performance-description :global(.ant-descriptions-item-label) {
color: black;
font-weight: 600;
}
.performance-description :global(.ant-descriptions-item-content) {
color: black;
font-weight: 400;
text-align: right;
}


