.menu-outer-layout {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
}

.menu-logo {
  text-align: center;
  background: white;
}

.user-info {
  padding-left: 1rem;
  padding-bottom: 2rem;
  height: 2rem;
  transition: all 0.2s;
  color: lightgrey;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 255px;
}

.dealer {
  height: 2rem;
  padding-left: 1rem;
  transition: all 0.2s;
  color: lightgrey;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 255px;  
}

.ant-menu-item {
  transition: transform 0.3s ease-in-out;
}

.ant-menu-submenu-title:hover > .ant-menu-item-icon  {
  color: white;
  font-weight: bold;
  transform: scale(1.2);
}

.ant-menu-item:hover > .ant-menu-item-icon {
  color: white;
  font-weight: bold;
  transform: scale(1.2);
}

.ant-menu-submenu-arrow {
  color: white;
  font-weight: bold;
}

.ant-menu-title-content:hover {
  color: white;
  font-weight: bold;
}
