.quote-name {
  font-weight: 600;
  margin-bottom: .2rem;
}
.approval-type {
  text-transform: uppercase;
}

.quote-status {
  /*
  overflow: hidden;
  text-transform: uppercase;
  text-overflow: ellipsis;
  white-space: nowrap;
  */
  text-transform: uppercase;
  word-break:break-word;
}
.quote-id {
  font-weight: 400;
  font-size: .9rem;
  text-decoration: underline; 
  color: blue;
}

