.assemblyView :global(.ant-descriptions-item-container) {
  align-items: baseline;
}

.assemblyView :global(.bm-descriptions.ant-descriptions) table {
  width: auto;
}



