.expandedInfo-description th:global(.ant-descriptions-item) {
padding-bottom: 0px;
}
.expandedInfo-description :global(.ant-descriptions-item-label) {
color: black;
font-weight: 600;
}
.expandedInfo-description :global(.ant-descriptions-item-content) {
color: black;
font-weight: 400;
}
.open-btn:global(.ant-btn-primary) {
  background-color: red;
}
.pending-btn:global(.ant-btn-primary) {
  background-color: gold;
}
.done-btn:global(.ant-btn-primary) {
  background-color: grey;
}

