
/* :root { */
  /* --circular-cursor-radius: var(--circular-cursor-radius-value, 49); */
  /* --circular-cursor-url: var(--circular-cursor-url-value, url("../assets/images/circle_60.png")); */
/* } */

.joystick {
  cursor: url(../assets/images/joystick_30.png) 15 15, auto;
}

.joystick_large {
  cursor: url(../assets/images/joystick_110.png) 55 55, auto;
}

.gauge {
  cursor: url(../assets/images/gauge_50.png) 25 25, auto;
}

.gauge_large {
  cursor: url(../assets/images/gauge_110.png) 55 55, auto;
}

.switch_light {
  cursor: url(../assets/images/switch_20.png) 10 18, auto;
}

.switch_light_large {
  cursor: url(../assets/images/switch_75.png) 30 54, auto;
}

.break_valve {
  cursor: url(../assets/images/break_valve_50.png) 25 25, auto;
}

.break_valve_large {
  cursor: url(../assets/images/break_valve_110.png) 55 55, auto;
}

.cursor {
  cursor: default;
}

.click {
  background-color: yellow;
}

.removeIcon {
  font-size: 20px;
  margin-left: 5.5rem;
  margin-top: 0.3rem;
}

.upIcon {
  font-size: 20px;
  margin-left: 2rem;
  margin-top: 0.3rem;
}

.downIcon {
  font-size: 20px;
  margin-left: 5.5rem;
  margin-top: 0.3rem;
}

.mouse_coordinate {
  font-size: 24px; 
  color: #1890ff; 
  font-weight: bolder;
  text-align: center;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
}

.component_tag {
  border: 1px solid darkgray;
  background: rgb(236, 235, 235);
  padding: 2px 7px;
  border-radius: 3px;
  margin-bottom: 2px;
  margin-top: 2px;
  font-size: 15px;
  margin-left: 5px
}

