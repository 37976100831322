.categoryView :global(.ant-descriptions-item-container) {
  align-items: baseline;
}

.categoryView :global(.bm-descriptions.ant-descriptions) table {
  width: auto;
}



