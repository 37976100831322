/* transitions.css */
.step-container {
  position: relative;
  overflow: hidden;
  min-height: 300px;
}

.step-content {
  position: absolute;
  width: 100%;
  transition: transform 0.5s ease-in-out;
}

.step-enter-right {
  transform: translateX(100%);
}

.step-enter-left {
  transform: translateX(-100%);
}

.step-enter-active {
  position: relative;
  transform: translateX(0);
}

.step-exit {
  transform: translateX(0);
}

.step-exit-active-right {
  transform: translateX(-100%);
}

.step-exit-active-left {
  transform: translateX(100%);
}

