.filter-controls {
  display: flex;
  justify-content: space-evenly;
  gap: .5rem;
  flex-wrap: wrap
}
.filter-controls .line1 {
  flex: 1;
  min-width: 17rem;
  gap: .5em;
}
.filter-controls .line2 {
  flex: 1;
  gap: .5em;
  display: flex;
  min-width: 17rem;
}
.filter-controls .line2 .approval-type-select {
  min-width: 12rem;
  flex: 1
}
.filter-controls .line2 > .approval-actioned-chk {
  margin-top: .3rem;
}

.desktop-filter-approval-actioned-chk {
  margin-top: .3rem;
}

.selected-column {
  background-color: lightblue;
}

.quote-name {
  font-weight: 600;
  margin-bottom: .2rem;
}
.approval-type {
  text-transform: uppercase;
}
.quote-id {
  font-weight: 400;
  font-size: .9rem;
  text-decoration: underline; 
  color: blue;
}



