.pricingView .basePrices {
    margin-bottom: 12px;
    width: 100%;
    max-width: 27rem;
    margin-right: -8rem;
}
.pricingView .basePrices .summaryRow {
    font-size: 16.38px;
}
.pricingView .basePrices th {
    text-align: right;
    padding-right: 12px;
}
.pricingView .basePrices td {
  text-align: right;
  width: 100px;
}
