@import '~antd/dist/reset.css';

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


#components-layout-demo-fixed-sider .logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.2);
}
.site-layout {
  background: #fff;
  min-height: 100vh;
  margin-bottom: -60px;
}
.site-layout-background  {
  background: #fff;
  padding: 2.5rem;
}
/* Portrait */
@media (max-width: 801px) {
  .site-layout-background  {
    padding: 1rem;
  }
}

.ant-card-head-title {
  white-space: normal;
}

.readOnlyInputStyle.ant-input {
  border: none;
  border-bottom: solid black 1px;
  color: black;
  background-color: white;
  border-radius: 0px;
}
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.ant-dropdown-menu-item .ant-btn-text:hover {
  background-color:inherit !important;
}

.ant-dropdown-menu-title-content > button {
  width: 100%;
  display: block;
  text-align: left;
}

.ant-btn-link span {
  text-decoration: underline;
  text-underline-offset: 0.5em;
  color: black;
}

.ghostBmButton[disabled]:hover {
  color: rgba(0, 0, 0, 0.25) !important;
}

.ghostBmButton[disabled] span, .ghostBmButton[disabled]:hover span {
  border-color: rgba(0, 0, 0, 0.25) !important;
}

.ghostBmButton:hover {
  background: none !important;
  color: #1677ff !important;
}

.ghostBmButton:hover span {
  border-color: #1677ff !important;
}

.disabledBmButton {
  cursor: not-allowed;
  border-color: #d9d9d9;
  color: rgba(0, 0, 0, 0.25);
  background-color: rgba(0, 0, 0, 0.04);
  box-shadow: none;
}
.disabledBmButton:hover {
  cursor: not-allowed;
  border-color: #d9d9d9 !important;
  color: rgba(0, 0, 0, 0.25) !important;
  background-color: rgba(0, 0, 0, 0.04) !important;
  box-shadow: none;
}

ul.csl {
  list-style: none;
  padding: 0;
  margin: 0;
}

ul.csl li {
  display: inline;
}

ul.csl li::after {
  content: ", ";
}

ul.csl li:last-child::after {
  content: "";
}
.bm-descriptions th.ant-descriptions-item {
padding-bottom: 0px;
}
.bm-descriptions .ant-descriptions-item-label {
color: black;
font-weight: 600;
}
.bm-descriptions .ant-descriptions-item-content {
color: black;
font-weight: 400;
}


